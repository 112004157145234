/* Links */
a,
a:focus,
a:hover {
  color: #fff;
}

/*
 * Base structure
 */
html,
body {
  height: 100%;
  background-color: #111;
}

body {
  display: -ms-flexbox;
  display: flex;
  color: #fff;
  text-shadow: 0 .05rem .1rem rgba(0, 0, 0, .5);
  box-shadow: inset 0 0 5rem rgba(0, 0, 0, .5);
}

h1 {
  font-family: 'Uncial Antiqua', cursive;
}

thead {
  background-color: #00ff00;
  color: black;
  text-shadow: none;
}

caption {
  caption-side: top;
  background-color: none;
  text-align: left;
}

.table {
  max-width: none;
  table-layout: fixed;
  word-wrap: break-word;
}

.nav.nav-pills .nav-link.active {
  background-color: #00ff00;
  color: black;
  text-shadow: none;
}

.drop-filter, .drop-filter:focus, .show.dropdown .drop-filter:focus, .drop-filter:hover {
  background-color: #00ff00;
  border-color: #00ff00;
  color: black;
  text-shadow: none;
  box-shadow: none
} 

.show.dropdown .drop-filter:focus {
  box-shadow: 0 0 0 0.2rem rgba(0,255,0,.5);
}

@media only screen and (max-width: 600px) {
  .nav-link {
    padding: .25rem .5rem;
    font-size: .95rem;
  }
}

.col-title {
  width: 40%;
}
.col-author {
  width: 32%;
}
.col-subject {
  width: 28%;
}

.dropdown .drop-filter {
  padding: 0.25rem 0.5rem;
  font-size: 0.95rem;
}

/*Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
  .col-title {
    width: 50%;
  }
  .col-author {
    width: 20%;
  }
  .col-subject {
    width: 20%;
  }
  .dropdown .drop-filter {
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
  }
}

/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {  
  .col-title {
    width: 25%;
  }
  .col-author {
    width: 15%;
  }
  .col-subject {
    width: 15%;
  }
}

/*Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {  
  .col-title {
    width: 30%;
  }
  .col-author {
    width: 20%;
  }
  .col-subject {
    width: 15%;
  }
}

/*Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {  
  .col-title {
    width: 35%;
  }
  .col-author {
    width: 20%;
  }
  .col-subject {
    width: 15%;
  }
}